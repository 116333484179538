import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaAdobe, FaTags, FaMobileAlt, FaChartLine, FaBong, FaChalkboardTeacher } from 'react-icons/fa';
import { DiGoogleAnalytics } from 'react-icons/di';
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import Img from 'gatsby-image/withIEPolyfill'
import { FaCode } from 'react-icons/fa'

import {IoIosArrowDroprightCircle} from 'react-icons/io';
import { AiTwotoneExperiment } from 'react-icons/ai';

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Background = styled.div`
  background: #0384fc;
  color: white;
  padding: 5rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let Service = ({title, Icon = FaHome, link, details}) => (
  <div class="col-md-4">
    <Link to={link}>
      <Box>
        <Icon size={40}/>
        <h4 className="mt-3">{title}</h4>
        <p>{details}</p>
      </Box>
    </Link>
  </div>
)

export default () => (
  <Layout>
    <SEO title="Training | Empathy | Data agency" description="Empathy help business make better digital presence with data. Specalize in web / app tracking, tag manager implementation, data visaulization and AB testing." keywords="web analytics, app analytics, Google Analytics, Google Tag Manager"/>
    <Background>
      <h2>"Learning never exhausts the mind."</h2>
      <p>Leonardo da Vinci - famous polymath, painter of Mona Lisa.</p>
    </Background>
    <Container className="pt-4">
      <div className="text-center">
        <h1>Corporate Training</h1>
        <p className="text-muted">Master new knowledge from analytics concepts to platform usage</p>
        <p>New to the concept of data analytics? Unsure how to leverage analytics platforms to understand your customers?</p>
        <p>We offer a range of workshops that are customized according to your business needs.</p>
      </div>
    </Container>
    <Container className="py-4 service">
      <h2 className="mb-4">Our Courses / Workshops</h2>
      <Row>
        <Service title="Data Analytics for Marketers" details="Introduce data analytics concepts and give examples on how to apply them." Icon={AiTwotoneExperiment}/>
        <Service title="Introduction to Google Analytics (GA)" details="Introduce basic GA concepts and walkthrough key GA reports." Icon={DiGoogleAnalytics}/>
        <Service title="Advanced Google Analytics (GA)" details="Explain how to customize GA to your needs and use advanced GA features." Icon={DiGoogleAnalytics}/>
      </Row>
      <Row>
        <Service title="Adobe Analytics for Google Analytics Users" details="Explain how to use Adobe Analytics to do what you can do in GA." Icon={FaAdobe}/>
        <Service title="Introduction to Adobe Analytics (AA)" details="Introduce basic AA concepts and hands on dashboard creation in AA." Icon={FaAdobe}/>
        <Service title="Introduction to Google Tag Manager (GTM)" details="Introduce tag management concept and how to use GTM to tag your website." Icon={FaTags}/>
      </Row>
    </Container>   
    <StyledBackground>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <h3 className="text-center">Contact Us</h3>
                <Hr/>
                <Form/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
  </Layout>
)
